<template>
  <calendesk-tabs v-show="getStatsLoaded" :items="getItems" :route="route" />
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import path from "@/router/path";
import CalendeskTabs from "@/components/CTabs/CalendeskTabs.vue";
import roleActions from "@/calendesk/mixins/roleActions";
import sharedTabsActions from "@/calendesk/mixins/sharedTabsActions";

export default {
  name: "SalesWrapper",
  components: { CalendeskTabs },
  mixins: [sharedActions, roleActions, sharedTabsActions],
  data() {
    return {
      route: path.sales.main,
    };
  },
  computed: {
    getItems() {
      const items = [];

      items.push({
        id: this.availableTabs.sales.events,
        title: this.$trans("bookings"),
        props: {},
        route: {
          name: this.route,
          params: { tab: this.availableTabs.sales.events },
        },
        content: () =>
          import("@/views/dashboard/pages/Sales/Events/CalendeskEvents"),
      });

      if (this.loggedUserCanSeeUserSubscriptionTransactions) {
        items.push({
          id: this.availableTabs.sales.subscriptions,
          title: this.$trans("user_subscriptions"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.sales.subscriptions },
          },
          content: () =>
            import(
              "@/views/dashboard/pages/Sales/UserSubscriptions/UserSubscriptionList"
            ),
        });
      }

      if (this.loggedUserCanSeeProductsTransactions) {
        items.push({
          id: this.availableTabs.sales.products,
          title: this.$trans("simple_store_products"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.sales.products },
          },
          content: () =>
            import(
              "@/views/dashboard/pages/Sales/SimpleProductStoreTransactions/SimpleStoreTransactionList"
            ),
        });
      }

      return items;
    },
  },
};
</script>
